
import { defineComponent, ref, onMounted, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      username: "",
      password: "",
      displayLogin : false,
    });
    const { t } = useI18n()

    //let username = "";
    //let password = "";

    const submitButton = ref<HTMLElement | null>(null);

    const formLogin = ref<HTMLFormElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        .email(t("L'identifiant doit être un email valide"))
        .required(t("L'identifiant est obligatoire"))
        .label("username"),
      password: Yup.string()
        .required(t("Le mot de passe est obligatoire"))
        .label("Password"),
    });



    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(async () => {

            localStorage.setItem("userLogins", JSON.stringify(values))
            if (router.currentRoute.value.params.loginpassword) {
              if (router.currentRoute.value.params.redirect) {
                const stringRec = (router.currentRoute.value.params.redirect) as string;
                router.push({ name: stringRec });
              } else {
                router.push({ name: "profile-overview" });  //stillboard
              }
              return true;
            }
              
            if (router.currentRoute.value.params.redirect) {
              const stringRec = (router.currentRoute.value.params.redirect) as string;             

              if (router.currentRoute.value.params.otherparam) {
                router.push({ name: stringRec, params: {otherparam: router.currentRoute.value.params.otherparam} });
              } else {
                router.push({ name: stringRec });
              }


            } else {
              router.push({ name: "profile-overview" });
            }

            if (window.matchMedia('(display-mode: standalone)').matches) {
              const notifPerm = await Notification.requestPermission()
              if(!("serviceWorker" in navigator) || !("Notification" in window) || notifPerm !== "granted"){
                return;
              }else{
                const registration = await navigator.serviceWorker.register("/sw.js");
                const subscription = await registration.pushManager.getSubscription();  
                await navigator.serviceWorker.ready
                registration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: process.env.VUE_APP_VAPID_PUBLIC_KEY,
                }).then(async (s)=>{
                  mAxiosApi.post("/setTokenPush", {token: JSON.stringify(s)})
                });
              }
            }

          })
          .catch(() => {
            Swal.fire({
              text: t("Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion."),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("Retourner sur la page de connexion"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 500);
    };

    const getEnv = () => {
      if (process.env.VUE_APP_ENVIRONNEMENT == "LOCAL" || process.env.VUE_APP_ENVIRONNEMENT == "RECETTE") {
        return "dev";
      }
    }

    onMounted(async () => {
      localStorage.removeItem('storedConst');
      if (store.getters.isUserAuthenticated){
        if (router.currentRoute.value.params.redirect) {
          const stringRec = (router.currentRoute.value.params.redirect) as string;             
          if (router.currentRoute.value.params.otherparam) {
            router.push({ name: stringRec, params: {otherparam: router.currentRoute.value.params.otherparam} });
          } else {
            router.push({ name: stringRec });
          }
          return false;
        } 
      }

      state.displayLogin = true;

      if (router.currentRoute.value.params.loginpassword) {
        store.dispatch(Actions.LOGOUT);
        const stringRec = (router.currentRoute.value.params.loginpassword) as string;
        state.username = window.atob(stringRec).split("|$|")[0];
        state.password = window.atob(stringRec).split("|$|")[1];
        
        if (submitButton.value) submitButton.value.click();
      }
      if (localStorage.getItem('publicKey') && localStorage.getItem('userLogins') && /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        let challenge = new Uint8Array(64);
        crypto.getRandomValues(challenge);
        challenge =  Uint8Array.from(challenge + '', c => c.charCodeAt(0));
        const publicKeyString = localStorage.getItem('publicKey') as string;
        const publicKeyDecoded = new Uint8Array([...publicKeyString].map(char => char.charCodeAt(0))).buffer;
        const publicKey = {
          challenge: challenge,
          rpId: process.env.VUE_APP_CURRENT_URL,
          allowCredentials: [{
            type: "public-key",
            id: publicKeyDecoded,
            transports: ["usb", "ble", "nfc"],
          }],
          authenticatorSelection: {
            requireResidentKey: true
          },
          timeout: 60000,
        } as any;

        await navigator.credentials.get({ publicKey }) as any;
        onSubmitLogin(JSON.parse(localStorage.getItem("userLogins") as any))
      }
    });   
    
    const updateTest = () => {
      window.location.href = window.location.href.replace(/#.*$/, '?t=' + Math.random());
    }

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
      formLogin,
      updateTest,
      getEnv
    };
  },
});
